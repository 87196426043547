.colour-picker-demo {
  display: inline-block;
  float: left;
  width: 72px;
  height: 72px;
  font-size: 60px;
  text-align: center;
  margin-right: 6px;
}
.colour-picker .cell {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
}
.colour-picker.foreground .cell {
  background-image: url('/img/grey-check.png');
}
.colour-picker.foreground .cell-inner {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 24px;
  height: 24px;
}
.fx00 {
  color: #000000;
}
.bx00 {
  background-color: #000000;
}
.fx01 {
  color: #000033;
}
.bx01 {
  background-color: #000033;
}
.fx02 {
  color: #000066;
}
.bx02 {
  background-color: #000066;
}
.fx03 {
  color: #00009a;
}
.bx03 {
  background-color: #00009a;
}
.fx04 {
  color: #0000cd;
}
.bx04 {
  background-color: #0000cd;
}
.fx05 {
  color: #002000;
}
.bx05 {
  background-color: #002000;
}
.fx06 {
  color: #002033;
}
.bx06 {
  background-color: #002033;
}
.fx07 {
  color: #002066;
}
.bx07 {
  background-color: #002066;
}
.fx08 {
  color: #00209a;
}
.bx08 {
  background-color: #00209a;
}
.fx09 {
  color: #0020cd;
}
.bx09 {
  background-color: #0020cd;
}
.fx0a {
  color: #004000;
}
.bx0a {
  background-color: #004000;
}
.fx0b {
  color: #004033;
}
.bx0b {
  background-color: #004033;
}
.fx0c {
  color: #004066;
}
.bx0c {
  background-color: #004066;
}
.fx0d {
  color: #00409a;
}
.bx0d {
  background-color: #00409a;
}
.fx0e {
  color: #0040cd;
}
.bx0e {
  background-color: #0040cd;
}
.fx0f {
  color: #006000;
}
.bx0f {
  background-color: #006000;
}
.fx10 {
  color: #006033;
}
.bx10 {
  background-color: #006033;
}
.fx11 {
  color: #006066;
}
.bx11 {
  background-color: #006066;
}
.fx12 {
  color: #00609a;
}
.bx12 {
  background-color: #00609a;
}
.fx13 {
  color: #0060cd;
}
.bx13 {
  background-color: #0060cd;
}
.fx14 {
  color: #008000;
}
.bx14 {
  background-color: #008000;
}
.fx15 {
  color: #008033;
}
.bx15 {
  background-color: #008033;
}
.fx16 {
  color: #008066;
}
.bx16 {
  background-color: #008066;
}
.fx17 {
  color: #00809a;
}
.bx17 {
  background-color: #00809a;
}
.fx18 {
  color: #0080cd;
}
.bx18 {
  background-color: #0080cd;
}
.fx19 {
  color: #00a000;
}
.bx19 {
  background-color: #00a000;
}
.fx1a {
  color: #00a033;
}
.bx1a {
  background-color: #00a033;
}
.fx1b {
  color: #00a066;
}
.bx1b {
  background-color: #00a066;
}
.fx1c {
  color: #00a09a;
}
.bx1c {
  background-color: #00a09a;
}
.fx1d {
  color: #00a0cd;
}
.bx1d {
  background-color: #00a0cd;
}
.fx1e {
  color: #00c000;
}
.bx1e {
  background-color: #00c000;
}
.fx1f {
  color: #00c033;
}
.bx1f {
  background-color: #00c033;
}
.fx20 {
  color: #00c066;
}
.bx20 {
  background-color: #00c066;
}
.fx21 {
  color: #00c09a;
}
.bx21 {
  background-color: #00c09a;
}
.fx22 {
  color: #00c0cd;
}
.bx22 {
  background-color: #00c0cd;
}
.fx23 {
  color: #00e000;
}
.bx23 {
  background-color: #00e000;
}
.fx24 {
  color: #00e033;
}
.bx24 {
  background-color: #00e033;
}
.fx25 {
  color: #00e066;
}
.bx25 {
  background-color: #00e066;
}
.fx26 {
  color: #00e09a;
}
.bx26 {
  background-color: #00e09a;
}
.fx27 {
  color: #00e0cd;
}
.bx27 {
  background-color: #00e0cd;
}
.fx28 {
  color: #2b0000;
}
.bx28 {
  background-color: #2b0000;
}
.fx29 {
  color: #2b0033;
}
.bx29 {
  background-color: #2b0033;
}
.fx2a {
  color: #2b0066;
}
.bx2a {
  background-color: #2b0066;
}
.fx2b {
  color: #2b009a;
}
.bx2b {
  background-color: #2b009a;
}
.fx2c {
  color: #2b00cd;
}
.bx2c {
  background-color: #2b00cd;
}
.fx2d {
  color: #2b2000;
}
.bx2d {
  background-color: #2b2000;
}
.fx2e {
  color: #2b2033;
}
.bx2e {
  background-color: #2b2033;
}
.fx2f {
  color: #2b2066;
}
.bx2f {
  background-color: #2b2066;
}
.fx30 {
  color: #2b209a;
}
.bx30 {
  background-color: #2b209a;
}
.fx31 {
  color: #2b20cd;
}
.bx31 {
  background-color: #2b20cd;
}
.fx32 {
  color: #2b4000;
}
.bx32 {
  background-color: #2b4000;
}
.fx33 {
  color: #2b4033;
}
.bx33 {
  background-color: #2b4033;
}
.fx34 {
  color: #2b4066;
}
.bx34 {
  background-color: #2b4066;
}
.fx35 {
  color: #2b409a;
}
.bx35 {
  background-color: #2b409a;
}
.fx36 {
  color: #2b40cd;
}
.bx36 {
  background-color: #2b40cd;
}
.fx37 {
  color: #2b6000;
}
.bx37 {
  background-color: #2b6000;
}
.fx38 {
  color: #2b6033;
}
.bx38 {
  background-color: #2b6033;
}
.fx39 {
  color: #2b6066;
}
.bx39 {
  background-color: #2b6066;
}
.fx3a {
  color: #2b609a;
}
.bx3a {
  background-color: #2b609a;
}
.fx3b {
  color: #2b60cd;
}
.bx3b {
  background-color: #2b60cd;
}
.fx3c {
  color: #2b8000;
}
.bx3c {
  background-color: #2b8000;
}
.fx3d {
  color: #2b8033;
}
.bx3d {
  background-color: #2b8033;
}
.fx3e {
  color: #2b8066;
}
.bx3e {
  background-color: #2b8066;
}
.fx3f {
  color: #2b809a;
}
.bx3f {
  background-color: #2b809a;
}
.fx40 {
  color: #2b80cd;
}
.bx40 {
  background-color: #2b80cd;
}
.fx41 {
  color: #2ba000;
}
.bx41 {
  background-color: #2ba000;
}
.fx42 {
  color: #2ba033;
}
.bx42 {
  background-color: #2ba033;
}
.fx43 {
  color: #2ba066;
}
.bx43 {
  background-color: #2ba066;
}
.fx44 {
  color: #2ba09a;
}
.bx44 {
  background-color: #2ba09a;
}
.fx45 {
  color: #2ba0cd;
}
.bx45 {
  background-color: #2ba0cd;
}
.fx46 {
  color: #2bc000;
}
.bx46 {
  background-color: #2bc000;
}
.fx47 {
  color: #2bc033;
}
.bx47 {
  background-color: #2bc033;
}
.fx48 {
  color: #2bc066;
}
.bx48 {
  background-color: #2bc066;
}
.fx49 {
  color: #2bc09a;
}
.bx49 {
  background-color: #2bc09a;
}
.fx4a {
  color: #2bc0cd;
}
.bx4a {
  background-color: #2bc0cd;
}
.fx4b {
  color: #2be000;
}
.bx4b {
  background-color: #2be000;
}
.fx4c {
  color: #2be033;
}
.bx4c {
  background-color: #2be033;
}
.fx4d {
  color: #2be066;
}
.bx4d {
  background-color: #2be066;
}
.fx4e {
  color: #2be09a;
}
.bx4e {
  background-color: #2be09a;
}
.fx4f {
  color: #2be0cd;
}
.bx4f {
  background-color: #2be0cd;
}
.fx50 {
  color: #550000;
}
.bx50 {
  background-color: #550000;
}
.fx51 {
  color: #550033;
}
.bx51 {
  background-color: #550033;
}
.fx52 {
  color: #550066;
}
.bx52 {
  background-color: #550066;
}
.fx53 {
  color: #55009a;
}
.bx53 {
  background-color: #55009a;
}
.fx54 {
  color: #5500cd;
}
.bx54 {
  background-color: #5500cd;
}
.fx55 {
  color: #552000;
}
.bx55 {
  background-color: #552000;
}
.fx56 {
  color: #552033;
}
.bx56 {
  background-color: #552033;
}
.fx57 {
  color: #552066;
}
.bx57 {
  background-color: #552066;
}
.fx58 {
  color: #55209a;
}
.bx58 {
  background-color: #55209a;
}
.fx59 {
  color: #5520cd;
}
.bx59 {
  background-color: #5520cd;
}
.fx5a {
  color: #554000;
}
.bx5a {
  background-color: #554000;
}
.fx5b {
  color: #554033;
}
.bx5b {
  background-color: #554033;
}
.fx5c {
  color: #554066;
}
.bx5c {
  background-color: #554066;
}
.fx5d {
  color: #55409a;
}
.bx5d {
  background-color: #55409a;
}
.fx5e {
  color: #5540cd;
}
.bx5e {
  background-color: #5540cd;
}
.fx5f {
  color: #556000;
}
.bx5f {
  background-color: #556000;
}
.fx60 {
  color: #556033;
}
.bx60 {
  background-color: #556033;
}
.fx61 {
  color: #556066;
}
.bx61 {
  background-color: #556066;
}
.fx62 {
  color: #55609a;
}
.bx62 {
  background-color: #55609a;
}
.fx63 {
  color: #5560cd;
}
.bx63 {
  background-color: #5560cd;
}
.fx64 {
  color: #558000;
}
.bx64 {
  background-color: #558000;
}
.fx65 {
  color: #558033;
}
.bx65 {
  background-color: #558033;
}
.fx66 {
  color: #558066;
}
.bx66 {
  background-color: #558066;
}
.fx67 {
  color: #55809a;
}
.bx67 {
  background-color: #55809a;
}
.fx68 {
  color: #5580cd;
}
.bx68 {
  background-color: #5580cd;
}
.fx69 {
  color: #55a000;
}
.bx69 {
  background-color: #55a000;
}
.fx6a {
  color: #55a033;
}
.bx6a {
  background-color: #55a033;
}
.fx6b {
  color: #55a066;
}
.bx6b {
  background-color: #55a066;
}
.fx6c {
  color: #55a09a;
}
.bx6c {
  background-color: #55a09a;
}
.fx6d {
  color: #55a0cd;
}
.bx6d {
  background-color: #55a0cd;
}
.fx6e {
  color: #55c000;
}
.bx6e {
  background-color: #55c000;
}
.fx6f {
  color: #55c033;
}
.bx6f {
  background-color: #55c033;
}
.fx70 {
  color: #55c066;
}
.bx70 {
  background-color: #55c066;
}
.fx71 {
  color: #55c09a;
}
.bx71 {
  background-color: #55c09a;
}
.fx72 {
  color: #55c0cd;
}
.bx72 {
  background-color: #55c0cd;
}
.fx73 {
  color: #55e000;
}
.bx73 {
  background-color: #55e000;
}
.fx74 {
  color: #55e033;
}
.bx74 {
  background-color: #55e033;
}
.fx75 {
  color: #55e066;
}
.bx75 {
  background-color: #55e066;
}
.fx76 {
  color: #55e09a;
}
.bx76 {
  background-color: #55e09a;
}
.fx77 {
  color: #55e0cd;
}
.bx77 {
  background-color: #55e0cd;
}
.fx78 {
  color: #800000;
}
.bx78 {
  background-color: #800000;
}
.fx79 {
  color: #800033;
}
.bx79 {
  background-color: #800033;
}
.fx7a {
  color: #800066;
}
.bx7a {
  background-color: #800066;
}
.fx7b {
  color: #80009a;
}
.bx7b {
  background-color: #80009a;
}
.fx7c {
  color: #8000cd;
}
.bx7c {
  background-color: #8000cd;
}
.fx7d {
  color: #802000;
}
.bx7d {
  background-color: #802000;
}
.fx7e {
  color: #802033;
}
.bx7e {
  background-color: #802033;
}
.fx7f {
  color: #802066;
}
.bx7f {
  background-color: #802066;
}
.fx80 {
  color: #80209a;
}
.bx80 {
  background-color: #80209a;
}
.fx81 {
  color: #8020cd;
}
.bx81 {
  background-color: #8020cd;
}
.fx82 {
  color: #804000;
}
.bx82 {
  background-color: #804000;
}
.fx83 {
  color: #804033;
}
.bx83 {
  background-color: #804033;
}
.fx84 {
  color: #804066;
}
.bx84 {
  background-color: #804066;
}
.fx85 {
  color: #80409a;
}
.bx85 {
  background-color: #80409a;
}
.fx86 {
  color: #8040cd;
}
.bx86 {
  background-color: #8040cd;
}
.fx87 {
  color: #806000;
}
.bx87 {
  background-color: #806000;
}
.fx88 {
  color: #806033;
}
.bx88 {
  background-color: #806033;
}
.fx89 {
  color: #806066;
}
.bx89 {
  background-color: #806066;
}
.fx8a {
  color: #80609a;
}
.bx8a {
  background-color: #80609a;
}
.fx8b {
  color: #8060cd;
}
.bx8b {
  background-color: #8060cd;
}
.fx8c {
  color: #808000;
}
.bx8c {
  background-color: #808000;
}
.fx8d {
  color: #808033;
}
.bx8d {
  background-color: #808033;
}
.fx8e {
  color: #808066;
}
.bx8e {
  background-color: #808066;
}
.fx8f {
  color: #80809a;
}
.bx8f {
  background-color: #80809a;
}
.fx90 {
  color: #8080cd;
}
.bx90 {
  background-color: #8080cd;
}
.fx91 {
  color: #80a000;
}
.bx91 {
  background-color: #80a000;
}
.fx92 {
  color: #80a033;
}
.bx92 {
  background-color: #80a033;
}
.fx93 {
  color: #80a066;
}
.bx93 {
  background-color: #80a066;
}
.fx94 {
  color: #80a09a;
}
.bx94 {
  background-color: #80a09a;
}
.fx95 {
  color: #80a0cd;
}
.bx95 {
  background-color: #80a0cd;
}
.fx96 {
  color: #80c000;
}
.bx96 {
  background-color: #80c000;
}
.fx97 {
  color: #80c033;
}
.bx97 {
  background-color: #80c033;
}
.fx98 {
  color: #80c066;
}
.bx98 {
  background-color: #80c066;
}
.fx99 {
  color: #80c09a;
}
.bx99 {
  background-color: #80c09a;
}
.fx9a {
  color: #80c0cd;
}
.bx9a {
  background-color: #80c0cd;
}
.fx9b {
  color: #80e000;
}
.bx9b {
  background-color: #80e000;
}
.fx9c {
  color: #80e033;
}
.bx9c {
  background-color: #80e033;
}
.fx9d {
  color: #80e066;
}
.bx9d {
  background-color: #80e066;
}
.fx9e {
  color: #80e09a;
}
.bx9e {
  background-color: #80e09a;
}
.fx9f {
  color: #80e0cd;
}
.bx9f {
  background-color: #80e0cd;
}
.fxa0 {
  color: #ab0000;
}
.bxa0 {
  background-color: #ab0000;
}
.fxa1 {
  color: #ab0033;
}
.bxa1 {
  background-color: #ab0033;
}
.fxa2 {
  color: #ab0066;
}
.bxa2 {
  background-color: #ab0066;
}
.fxa3 {
  color: #ab009a;
}
.bxa3 {
  background-color: #ab009a;
}
.fxa4 {
  color: #ab00cd;
}
.bxa4 {
  background-color: #ab00cd;
}
.fxa5 {
  color: #ab2000;
}
.bxa5 {
  background-color: #ab2000;
}
.fxa6 {
  color: #ab2033;
}
.bxa6 {
  background-color: #ab2033;
}
.fxa7 {
  color: #ab2066;
}
.bxa7 {
  background-color: #ab2066;
}
.fxa8 {
  color: #ab209a;
}
.bxa8 {
  background-color: #ab209a;
}
.fxa9 {
  color: #ab20cd;
}
.bxa9 {
  background-color: #ab20cd;
}
.fxaa {
  color: #ab4000;
}
.bxaa {
  background-color: #ab4000;
}
.fxab {
  color: #ab4033;
}
.bxab {
  background-color: #ab4033;
}
.fxac {
  color: #ab4066;
}
.bxac {
  background-color: #ab4066;
}
.fxad {
  color: #ab409a;
}
.bxad {
  background-color: #ab409a;
}
.fxae {
  color: #ab40cd;
}
.bxae {
  background-color: #ab40cd;
}
.fxaf {
  color: #ab6000;
}
.bxaf {
  background-color: #ab6000;
}
.fxb0 {
  color: #ab6033;
}
.bxb0 {
  background-color: #ab6033;
}
.fxb1 {
  color: #ab6066;
}
.bxb1 {
  background-color: #ab6066;
}
.fxb2 {
  color: #ab609a;
}
.bxb2 {
  background-color: #ab609a;
}
.fxb3 {
  color: #ab60cd;
}
.bxb3 {
  background-color: #ab60cd;
}
.fxb4 {
  color: #ab8000;
}
.bxb4 {
  background-color: #ab8000;
}
.fxb5 {
  color: #ab8033;
}
.bxb5 {
  background-color: #ab8033;
}
.fxb6 {
  color: #ab8066;
}
.bxb6 {
  background-color: #ab8066;
}
.fxb7 {
  color: #ab809a;
}
.bxb7 {
  background-color: #ab809a;
}
.fxb8 {
  color: #ab80cd;
}
.bxb8 {
  background-color: #ab80cd;
}
.fxb9 {
  color: #aba000;
}
.bxb9 {
  background-color: #aba000;
}
.fxba {
  color: #aba033;
}
.bxba {
  background-color: #aba033;
}
.fxbb {
  color: #aba066;
}
.bxbb {
  background-color: #aba066;
}
.fxbc {
  color: #aba09a;
}
.bxbc {
  background-color: #aba09a;
}
.fxbd {
  color: #aba0cd;
}
.bxbd {
  background-color: #aba0cd;
}
.fxbe {
  color: #abc000;
}
.bxbe {
  background-color: #abc000;
}
.fxbf {
  color: #abc033;
}
.bxbf {
  background-color: #abc033;
}
.fxc0 {
  color: #abc066;
}
.bxc0 {
  background-color: #abc066;
}
.fxc1 {
  color: #abc09a;
}
.bxc1 {
  background-color: #abc09a;
}
.fxc2 {
  color: #abc0cd;
}
.bxc2 {
  background-color: #abc0cd;
}
.fxc3 {
  color: #abe000;
}
.bxc3 {
  background-color: #abe000;
}
.fxc4 {
  color: #abe033;
}
.bxc4 {
  background-color: #abe033;
}
.fxc5 {
  color: #abe066;
}
.bxc5 {
  background-color: #abe066;
}
.fxc6 {
  color: #abe09a;
}
.bxc6 {
  background-color: #abe09a;
}
.fxc7 {
  color: #abe0cd;
}
.bxc7 {
  background-color: #abe0cd;
}
.fxc8 {
  color: #d50000;
}
.bxc8 {
  background-color: #d50000;
}
.fxc9 {
  color: #d50033;
}
.bxc9 {
  background-color: #d50033;
}
.fxca {
  color: #d50066;
}
.bxca {
  background-color: #d50066;
}
.fxcb {
  color: #d5009a;
}
.bxcb {
  background-color: #d5009a;
}
.fxcc {
  color: #d500cd;
}
.bxcc {
  background-color: #d500cd;
}
.fxcd {
  color: #d52000;
}
.bxcd {
  background-color: #d52000;
}
.fxce {
  color: #d52033;
}
.bxce {
  background-color: #d52033;
}
.fxcf {
  color: #d52066;
}
.bxcf {
  background-color: #d52066;
}
.fxd0 {
  color: #d5209a;
}
.bxd0 {
  background-color: #d5209a;
}
.fxd1 {
  color: #d520cd;
}
.bxd1 {
  background-color: #d520cd;
}
.fxd2 {
  color: #d54000;
}
.bxd2 {
  background-color: #d54000;
}
.fxd3 {
  color: #d54033;
}
.bxd3 {
  background-color: #d54033;
}
.fxd4 {
  color: #d54066;
}
.bxd4 {
  background-color: #d54066;
}
.fxd5 {
  color: #d5409a;
}
.bxd5 {
  background-color: #d5409a;
}
.fxd6 {
  color: #d540cd;
}
.bxd6 {
  background-color: #d540cd;
}
.fxd7 {
  color: #d56000;
}
.bxd7 {
  background-color: #d56000;
}
.fxd8 {
  color: #d56033;
}
.bxd8 {
  background-color: #d56033;
}
.fxd9 {
  color: #d56066;
}
.bxd9 {
  background-color: #d56066;
}
.fxda {
  color: #d5609a;
}
.bxda {
  background-color: #d5609a;
}
.fxdb {
  color: #d560cd;
}
.bxdb {
  background-color: #d560cd;
}
.fxdc {
  color: #d58000;
}
.bxdc {
  background-color: #d58000;
}
.fxdd {
  color: #d58033;
}
.bxdd {
  background-color: #d58033;
}
.fxde {
  color: #d58066;
}
.bxde {
  background-color: #d58066;
}
.fxdf {
  color: #d5809a;
}
.bxdf {
  background-color: #d5809a;
}
.fxe0 {
  color: #d580cd;
}
.bxe0 {
  background-color: #d580cd;
}
.fxe1 {
  color: #d5a000;
}
.bxe1 {
  background-color: #d5a000;
}
.fxe2 {
  color: #d5a033;
}
.bxe2 {
  background-color: #d5a033;
}
.fxe3 {
  color: #d5a066;
}
.bxe3 {
  background-color: #d5a066;
}
.fxe4 {
  color: #d5a09a;
}
.bxe4 {
  background-color: #d5a09a;
}
.fxe5 {
  color: #d5a0cd;
}
.bxe5 {
  background-color: #d5a0cd;
}
.fxe6 {
  color: #d5c000;
}
.bxe6 {
  background-color: #d5c000;
}
.fxe7 {
  color: #d5c033;
}
.bxe7 {
  background-color: #d5c033;
}
.fxe8 {
  color: #d5c066;
}
.bxe8 {
  background-color: #d5c066;
}
.fxe9 {
  color: #d5c09a;
}
.bxe9 {
  background-color: #d5c09a;
}
.fxea {
  color: #d5c0cd;
}
.bxea {
  background-color: #d5c0cd;
}
.fxeb {
  color: #d5e000;
}
.bxeb {
  background-color: #d5e000;
}
.fxec {
  color: #d5e033;
}
.bxec {
  background-color: #d5e033;
}
.fxed {
  color: #d5e066;
}
.bxed {
  background-color: #d5e066;
}
.fxee {
  color: #d5e09a;
}
.bxee {
  background-color: #d5e09a;
}
.fxef {
  color: #d5e0cd;
}
.bxef {
  background-color: #d5e0cd;
}
.fxf0 {
  color: #000000;
}
.bxf0 {
  background-color: #000000;
}
.fxf1 {
  color: #111111;
}
.bxf1 {
  background-color: #111111;
}
.fxf2 {
  color: #222222;
}
.bxf2 {
  background-color: #222222;
}
.fxf3 {
  color: #333333;
}
.bxf3 {
  background-color: #333333;
}
.fxf4 {
  color: #444444;
}
.bxf4 {
  background-color: #444444;
}
.fxf5 {
  color: #555555;
}
.bxf5 {
  background-color: #555555;
}
.fxf6 {
  color: #666666;
}
.bxf6 {
  background-color: #666666;
}
.fxf7 {
  color: #777777;
}
.bxf7 {
  background-color: #777777;
}
.fxf8 {
  color: #888888;
}
.bxf8 {
  background-color: #888888;
}
.fxf9 {
  color: #999999;
}
.bxf9 {
  background-color: #999999;
}
.fxfa {
  color: #aaaaaa;
}
.bxfa {
  background-color: #aaaaaa;
}
.fxfb {
  color: #bbbbbb;
}
.bxfb {
  background-color: #bbbbbb;
}
.fxfc {
  color: #cccccc;
}
.bxfc {
  background-color: #cccccc;
}
.fxfd {
  color: #dddddd;
}
.bxfd {
  background-color: #dddddd;
}
.fxfe {
  color: #eeeeee;
}
.bxfe {
  background-color: #eeeeee;
}
.fxff {
  color: #ffffff;
}
.bxff {
  background-color: #ffffff;
}
body {
  background-color: #000;
  color: #fb0;
  font-size: 18px;
  padding: 0;
  margin: 0;
}
.app-content {
  margin-left: 3em;
}
a,
a:link,
a:visited,
a:active,
a:hover {
  color: #fb0;
}
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
#compose-honk {
  border: 3px double #fb0;
  padding: 0 1em;
  background: black;
}
/* position reference for editor and sizer */
.editor-box {
  position: relative;
}
.editor-box > * {
  vertical-align: bottom;
}
.honk-editor {
  border: 1px solid #fb0;
  display: inline-block;
  position: relative;
  margin: 0;
}
.honk-editor .fg {
  position: absolute;
  top: 0;
}
.editor-mode-text {
  cursor: text;
}
.editor-mode-paint {
  cursor: crosshair;
}
.text-catcher {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  color: transparent;
  background-color: transparent;
  display: none;
}
.cursor {
  outline: 1px solid white;
  animation-duration: 1s;
  animation-name: cursor-blink;
  animation-iteration-count: infinite;
}
.paintgrid {
  position: absolute;
  top: 0;
}
@keyframes cursor-blink {
  0% {
    outline: 1px solid white;
  }
  2% {
    outline: 1px solid #ccc;
  }
  30% {
    outline: 1px solid #ccc;
  }
  50% {
    outline: 1px solid transparent;
  }
  80% {
    outline: 1px solid transparent;
  }
}
.honk-sizer {
  font-weight: bold;
  display: inline-block;
  border: 1px solid #fb0;
  margin-left: -1px;
  /* border join to edit box */
}
.swatch-group {
  display: inline-block;
}
.swatch-group .subswatches {
  display: none;
  position: absolute;
  width: 8em;
  background: black;
  border: 1px solid #fb0;
}
.swatch-group:hover .subswatches {
  display: block;
}
.swatch-group .swatch {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
}
/*
	NAVIGATION STUFF
*/
.things {
  position: fixed;
  top: 0;
  left: 0;
}
.things .thing {
  position: relative;
  box-sizing: border-box;
  right: 100%;
  padding: 0 1em 0 0;
  margin: -3px -2em 0 0;
  border: 3px double #fb0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
  transition: right 0.25s, margin-right 0.25s;
  text-align: right;
  background-color: black;
  line-height: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.things .thing:first-child {
  margin-top: 0;
}
.things .thing:hover {
  right: 0;
  margin-right: 0;
}
.thing .nav-tab {
  display: block;
}
.thing .tab-icon {
  margin-left: 0.5em;
  font-style: italic;
}
.user-info .avatar {
  height: 3em;
  width: 3em;
  object-fit: contain;
  object-position: bottom right;
}
.user-info .username {
  opacity: 0;
  transition: opacity 0.25s;
}
.user-info:hover .username {
  opacity: 1;
}
a {
  text-decoration: none !important;
}
